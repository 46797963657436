import styles from './styles.module.scss';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Collapse } from '@mui/material';

import RichTextEditor from '../../RichTextEditor';
import {
  BlockModel,
  EditorDataModel,
  ImageTypeModel,
  BlockModelBig,
  TPositionBlock,
  TSideBlock,
  TTopContentPosition,
  TDeletingBlock,
  ImageModel,
  TSideChangePositions
} from './model';
import { Blocks } from './Blocks';
import { ClickAddBlock } from './ClickAddBlock';
import { CreateImagesBlock } from '../components/CreateImagesBlock';
import { useAppDispatch, useAppSelector, useRedirectAndScroll } from '../../../hooks';
import { hideDialog, showDialog } from '../../../store/reducers/dialogsSlice';
import {
  deletePhotoFromQuiltAlbum,
  editQuilt
} from '../../../api/http-client/queries/quilts';
import {
  compareFiles,
  downloadImage,
  notifyMessagesQuiltMemorialUpdateCreate,
} from '../../../utilities';
import { useTranslates } from '../../../providers/translates';
import { Loader } from '../../Loader/Loader';
import { editUnpublishedQuilt } from '../../../api/http-client/queries/quiltQueries';
import {
  resetCurrentBlocks,
  setCurrentBlocks,
  setEditableQuiltState,
  setIsTriggerResetValuesComparing,
} from 'store/reducers/quiltSlice';
import { setOpen } from 'store/reducers/notifySlice';
import { getNewBlocksWithoutBlockBySide, getValueFromBlock } from './utils';
import { defaultBlock } from './const';
import { DefaultDialog } from '../../DefaultDialog';
import { useQuilt } from 'hooks/useQuilt';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ImageIcon from '@mui/icons-material/Image';
import { sendToCloud } from '../helpers/send-to-cloud';
import { CLOUD_IMAGE_URL } from '../constants/constants';
import { CreateMemorialSteps } from '../CreateMemorialSteps';

type TConfirmDelteing = {
  openModal: boolean;
  side?: TSideBlock;
  block: BlockModel;
  typeDeleting: TDeletingBlock;
};

interface CreateMemorialQuiltStepThreeProps {
  isActiveStep: number;
  steps: {
    index: number;
    title: string;
  }[];
}

export const CreateMemorialQuiltStepThree: FC<CreateMemorialQuiltStepThreeProps> = ({
  isActiveStep,
  steps
}) => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { translates } = useTranslates();
  const dispatch = useAppDispatch();
  const editableQuiltStore = useAppSelector((state) => state.quilt.editableQuilt);
  const { onlyScroll } = useRedirectAndScroll({});
  const { blocks: blocksUseQuilt, loading: loadingPublish } = useQuilt({ id: id || '' });

  const [blocks, setBlocks] = useState<BlockModel[]>(() => [
    ...blocksUseQuilt.getBlocksEditableQuilt(),
  ]);
  const [confirmDeleting, setConfirmDeleting] = useState<TConfirmDelteing>({
    openModal: false,
    side: 'left',
    block: blocks[0],
    typeDeleting: 'block',
  });
  const [currentBlockImage, setCurrentBlockImage] = useState<BlockModelBig | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState<File>();
  const [imagesForDelete, setImagesForDelete] = useState<string[]>([]);

  const side = currentBlockImage?.side;
  let imageName: string | null = null;

  if (side !== undefined && currentBlockImage) {
    const curImage = currentBlockImage.image as ImageModel;

    if (curImage?.[side]?.image?.originalImage) {
      imageName = curImage?.[side]?.image?.originalImage as string;
    }
  }

  useEffect(() => {
    onlyScroll('createQuiltHeader');
  }, []);

  // need for setting two blocks in the start
  useEffect(() => {
    if (blocks.length === 1) {
      setBlocks((state) => {
        return [...state, { ...defaultBlock() }, { ...defaultBlock() }];
      });
    }

    if (blocks.length === 2) {
      setBlocks((state) => {
        return [...state, { ...defaultBlock() }];
      });
    }

    return () => {
      dispatch(resetCurrentBlocks());
    };
  }, []);

  // init memorial
  useEffect(() => {
    if (!editableQuiltStore || !editableQuiltStore?.memorial?.data) return;

    const memorialData = JSON.parse(editableQuiltStore.memorial.data);

    setBlocks(memorialData);
  }, [editableQuiltStore]);

  // synchronization with store for preview
  useEffect(() => {
    dispatch(setCurrentBlocks(blocks));
  }, [blocks]);

  // move to currentBlockImage
  useEffect(() => {
    if (!currentBlockImage) return;

    const createImageBlock = document.querySelectorAll(`.${styles.editorBlock1}`);

    for (const imageBlock of Array.from(createImageBlock)) {
      const styles = window.getComputedStyle(imageBlock);

      if (styles.getPropertyValue('visibility') !== 'hidden') {
        setTimeout(() => {
          imageBlock.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 1000);
        break;
      }
    }
  }, [currentBlockImage]);

  useEffect(() => {
    return () => {
      dispatch(hideDialog('blocks'));
    };
  }, [dispatch]);

  const saveImageUrls = async (blocks: BlockModel[]) => {
    const parsedBlocks = JSON.parse(JSON.stringify(blocks));

    for (const block of parsedBlocks) {
      const { image } = block as Record<'image', ImageModel>;

      if (image) {
        const { left, right } = image;

        if (left && left.image?.file && !left.image?.file.includes(CLOUD_IMAGE_URL)) {
          left.image.file = await sendToCloud(id as string, left);
        }

        if (right && right.image?.file && !right.image?.file.includes(CLOUD_IMAGE_URL)) {
          right.image.file = await sendToCloud(id as string, right);
        }
      }
    }

    return parsedBlocks;
  };

  const onUpdate = async () => {
    if (!id) return;

    setLoading(true);

    const parsedBlocks = await saveImageUrls(blocks);

    for (const image of imagesForDelete) {
      await deletePhotoFromQuiltAlbum(id as string, image);
    }

    editQuilt(id, {
      ...editableQuiltStore,
      memorial: JSON.stringify(parsedBlocks) as never,
    })
      .then(() => {
        // get quilt after update
        editUnpublishedQuilt(id)
          .then((res) => {
            dispatch(setEditableQuiltState(res));
            dispatch(setCurrentBlocks(JSON.parse(res.memorial.data)));
            dispatch(setIsTriggerResetValuesComparing(true));
          })
          .then(() => {
            dispatch(
              setOpen(
                translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltUpdatedSuccess ||
                  notifyMessagesQuiltMemorialUpdateCreate.quiltUpdatedSuccess
              )
            );

            if (!editableQuiltStore.isContributor) {
              setSearchParams({
                step: '4',
              });

              setTimeout(() => {
                onlyScroll('createQuiltHeader');
              }, 100);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() =>
        dispatch(
          setOpen(
            translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltUpdatedError ||
              notifyMessagesQuiltMemorialUpdateCreate.quiltUpdatedError
          )
        )
      );
  };

  const getBlockTopContent = useMemo(() => {
    const topContentBlock = blocks.find((block) => block.topContent);

    return topContentBlock;
  }, [blocks]);

  const addNewBlockText = (
    position: TPositionBlock = 'full',
    side?: TSideBlock,
    blockId?: string
  ) => {
    const newBlockId = uuidv4();

    setCurrentBlockImage(null);

    setBlocks((state) => {
      if (!state) state = [];

      let newState = [...state];

      // logic for additional blocks
      if (blockId) {
        // when we have this block and need to add new in the side
        const currentBlockIndex = newState.findIndex((item) => item.id === blockId);

        if (!side || currentBlockIndex < 0) return state;

        if (typeof newState[currentBlockIndex]?.editorData === 'object') {
          const editorData = newState[currentBlockIndex]?.editorData as EditorDataModel;

          if (editorData) {
            newState = newState.map((item, index) => {
              if (index === currentBlockIndex) {
                return {
                  ...item,
                  editorData: {
                    ...(typeof item.editorData === 'object' ? item.editorData : null),
                    [side]: {
                      value: '',
                      isRender: true,
                    },
                  },
                };
              }

              return item;
            });
          }
        } else {
          newState[currentBlockIndex] = {
            ...newState[currentBlockIndex],
            editorData: {
              [side]: {
                value: '',
                isRender: true,
              },
            },
          };
        }
      } else {
        newState.push({
          id: newBlockId,
          position: position,
          editorData:
            position === 'half'
              ? {
                  [side || '']: {
                    value: '',
                    isRender: true,
                  },
                }
              : '',
        });
      }

      return newState;
    });
  };

  const addNewBlockImage = (
    position: TPositionBlock = 'full',
    side?: TSideBlock,
    blockId?: string,
    isEdit?: boolean
  ) => {
    setBlocks((state) => {
      const newBlockId = uuidv4();

      if (!state) state = [];

      const newState = [...state];

      // need for edit mode in image
      if (isEdit && side) {
        const createdBlockIndex = blocks.findIndex((block) => blockId === block.id);

        if (typeof blocks[createdBlockIndex].image === 'object') {
          // @ts-ignore
          const currentFile = blocks[createdBlockIndex].image?.[side].image.file;

          if (!editableQuiltStore?.album) return newState;

          for (const imageAlbum of editableQuiltStore.album) {
            const fileForCompareAsync = downloadImage(imageAlbum.small);
            const currentFileAsync = downloadImage(currentFile);

            fileForCompareAsync.then((fileForCompare) => {
              currentFileAsync.then((currentFile) => {
                compareFiles(fileForCompare, currentFile).then((isSame) => {
                  if (isSame) setCurrentImage(fileForCompare);
                });
              });
            });
          }
        }
      }

      if (position === 'half') {
        const createdBlockIndex = blocks.findIndex((block) => blockId === block.id);

        if (blocks[createdBlockIndex]) {
          const updateBlockData = {
            id: blocks[createdBlockIndex].id,
            position: position,
            side: side,
            image: {
              ...blocks[createdBlockIndex].image,
            },
          };

          setCurrentBlockImage(updateBlockData);
        } else {
          setCurrentBlockImage({
            id: newBlockId,
            newAddedBlock: true,
            position: position,
            side: side,
          });
        }
      } else {
        setCurrentBlockImage({
          id: newBlockId,
          newAddedBlock: true,
          position: position,
          image: null,
        });
      }

      return newState;
    });
  };

  const setEditorDataById = (
    id: string | undefined,
    newValue: string,
    side?: TSideBlock | null,
    topContentPosition?: TTopContentPosition
  ) => {
    setBlocks((state) => {
      if (!state) return state;

      const newState = [...state];

      const currentIndex = newState?.findIndex((item) => item.id === id);

      if (topContentPosition) {
        if (topContentPosition === 'top') {
          newState[currentIndex] = {
            ...newState[currentIndex],
            topContent: {
              ...newState[currentIndex].topContent,
              [topContentPosition]: newValue,
            },
          };

          return newState;
        }
      }

      const currentEditorData = newState[currentIndex].editorData;

      let newEditor: EditorDataModel | null = null;

      if (typeof currentEditorData === 'object' && side) {
        newEditor = {
          ...currentEditorData,
        };

        const currentSide = side as TSideBlock;

        newEditor[currentSide] = {
          isRender: newEditor[currentSide]?.isRender || true,
          value: newValue,
        };
      }

      newState[currentIndex] = {
        ...newState[currentIndex],
        editorData: newEditor ? newEditor : newValue,
      };

      return newState;
    });
  };

  const setImageBlock = (image: ImageTypeModel | null) => {
    if (!image) {
      setCurrentBlockImage(null);
      return;
    }

    setBlocks((state) => {
      if (!currentBlockImage) return state;

      if (!state) state = [];

      const newState = [...state];

      if (currentBlockImage?.position === 'header') {
        if (
          getBlockTopContent &&
          currentBlockImage?.side &&
          currentBlockImage.blockPositionTopContent
        ) {
          const blockTopContentIndex = newState.findIndex((item) => item.topContent);

          newState[blockTopContentIndex] = {
            ...getBlockTopContent,
            topContent: {
              ...getBlockTopContent?.topContent,
              [currentBlockImage?.side]: {
                [currentBlockImage.blockPositionTopContent]: {
                  value: image,
                  isRender: true,
                },
              },
            },
          };
        }

        return newState;
      }

      if (currentBlockImage?.position === 'half') {
        const currentBlockIndex = blocks.findIndex((block) => block.id === currentBlockImage.id);

        // define step of creating element
        if (currentBlockIndex >= 0) {
          if (!currentBlockImage.image) return state;

          const updateBlock = {
            ...blocks[currentBlockIndex],
            image: {
              ...currentBlockImage.image,
              [currentBlockImage.side || '']: {
                isRender: true,
                image: image,
              },
            },
          };

          newState[currentBlockIndex] = updateBlock;
        } else {
          newState.push({
            ...blocks[currentBlockIndex],
            ...currentBlockImage,
            image: {
              [currentBlockImage.side === 'right' ? 'right' : 'left']: {
                image: image,
                isRender: true,
              },
            },
          });
        }
      } else if (currentBlockImage?.image) {
        const index = newState.findIndex((item) => item.id === currentBlockImage.id);
        newState[index] = {
          ...newState[index],
          image,
        };
      } else {
        newState.push({
          ...currentBlockImage,
          image,
        });
      }

      return newState;
    });

    setCurrentBlockImage(null);
  };

  const changePositionBlocks = (
    id: string,
    side: TSideChangePositions,
    idTo: string,
    sideTo: TSideChangePositions
  ) => {
    if (id === idTo && side === sideTo) return;

    setBlocks((state) => {
      const currentBlock = getValueFromBlock(id, side, state);
      const toBlock = getValueFromBlock(idTo, sideTo, state);

      if (!currentBlock || !toBlock) return state;

      // for moving full blocks
      if ((side === 'full' && sideTo === 'full') || side === 'full' || sideTo === 'full') {
        const current = { ...state[currentBlock.index] };
        const to = { ...state[toBlock.index] };

        const newState = [...state];

        newState[toBlock.index] = current;
        newState[currentBlock.index] = to;

        return newState;
      }

      const isSameType = currentBlock?.type === toBlock?.type;

      const newState = [...state];

      // remove old values
      newState[currentBlock.index] = getNewBlocksWithoutBlockBySide(newState, currentBlock, side);
      newState[toBlock.index] = getNewBlocksWithoutBlockBySide(newState, toBlock, sideTo);

      if (toBlock.index === currentBlock.index) {
        // set for the same row; currentBlock.index - because the same index with toBlock
        newState[currentBlock.index] = {
          ...newState[currentBlock.index],
          ...(!isSameType
            ? {
                [currentBlock.type]: {
                  [sideTo]: currentBlock.value,
                },
              }
            : {}),
          [toBlock.type]: {
            ...(isSameType ? { [sideTo]: currentBlock.value } : {}),
            [side]: toBlock.value,
          },
        };
      } else {
        // save other values from another side (not moving side)
        const currentBlockSecondValue =
          Object.keys(newState[currentBlock.index][currentBlock.type] || {}).length > 0
            ? {
                type: currentBlock.type,
                value: newState[currentBlock.index][currentBlock.type],
              }
            : {
                type: toBlock.type,
                value: newState[currentBlock.index][toBlock.type],
              };

        const toBlockSecondValue =
          Object.keys(newState[toBlock.index][toBlock.type] || {}).length > 0
            ? {
                type: toBlock.type,
                value: newState[toBlock.index][toBlock.type],
              }
            : {
                type: currentBlock.type,
                value: newState[toBlock.index][currentBlock.type],
              };

        // set current block
        newState[currentBlock.index] = {
          ...newState[currentBlock.index],

          // setting when different type
          ...(!isSameType && toBlock.type !== currentBlockSecondValue.type
            ? {
                [currentBlockSecondValue.type]: currentBlockSecondValue.value,
              }
            : {}),

          [toBlock.type]: {
            // setting when the same type
            ...(typeof currentBlockSecondValue.value === 'object' &&
            currentBlockSecondValue.type === toBlock.type
              ? currentBlockSecondValue.value
              : {}),
            [side]: toBlock.value,
          },
        };

        // set to block
        newState[toBlock.index] = {
          ...newState[toBlock.index],

          // setting when different type
          ...(!isSameType && toBlock.type !== toBlockSecondValue.type
            ? {
                [toBlockSecondValue.type]: toBlockSecondValue.value,
              }
            : {}),

          [currentBlock.type]: {
            // setting when the same type
            ...(typeof toBlockSecondValue.value === 'object' &&
            toBlockSecondValue.type === currentBlock.type
              ? toBlockSecondValue.value
              : {}),
            [sideTo]: currentBlock.value,
          },
        };
      }

      return newState;
    });
  };

  const updateBlock = (
    blocks: BlockModel[],
    block: BlockModel,
    side?: TSideBlock,
    typeDeleting?: TDeletingBlock
  ): BlockModel[] => {
    const newValue = [...blocks];
    const indexElement = newValue.findIndex((item) => item.id === block.id);

    if (indexElement === -1) {
      return newValue;
    }

    if (side) {
      const images = newValue[indexElement].image as ImageModel;
      const editor = newValue[indexElement].editorData as EditorDataModel;

      const oppositeSide = side === 'left' ? 'right' : 'left';
      const isOppositeBlock =
        (images && images[oppositeSide]?.image) || (editor && !!editor[oppositeSide]?.isRender);

      if (typeDeleting === 'image') {
        const imageNameForDelete = (newValue[indexElement].image as ImageModel)?.[side]?.image?.file
          .split('/')
          .pop() as string;
        setImagesForDelete((prev) => [...prev, imageNameForDelete]);

        if (isOppositeBlock) {
          newValue[indexElement] = {
            ...newValue[indexElement],
            image: {
              ...newValue[indexElement].image,
              [side]: { isRender: side === 'left', image: null },
            },
          };
        } else if (Number(indexElement) <= 2) {
          newValue[indexElement] = {
            ...newValue[indexElement],
            image: {
              ...newValue[indexElement].image,
              [side]: { isRender: side === 'left', image: null },
            },
          };
        } else if (Number(indexElement) <= 2) {
          newValue[indexElement] = {
            id: newValue[indexElement].id,
            position: newValue[indexElement].position,
          };
        } else {
          newValue.splice(indexElement, 1);
        }
      } else if (typeDeleting === 'editor') {
        if (isOppositeBlock) {
          newValue[indexElement] = {
            ...newValue[indexElement],
            editorData: {
              ...(newValue[indexElement].editorData as object),
              [side]: { isRender: false, value: '' },
            },
          };
        } else if (Number(indexElement) <= 2) {
          newValue[indexElement] = {
            id: newValue[indexElement].id,
            position: newValue[indexElement].position,
            editorData: {},
          };
        } else {
          newValue.splice(indexElement, 1);
        }
      }
    } else {
      newValue.splice(indexElement, 1);
    }

    return newValue;
  };

  const deleteBlock = (block: BlockModel, side?: TSideBlock, typeDeleting?: TDeletingBlock) => {
    setBlocks((prev) => updateBlock(prev, block, side, typeDeleting));
    setConfirmDeleting((prev) => {
      return { ...prev, openModal: false };
    });
  };

  const showDialogBlocks = () => {
    dispatch(showDialog('blocks'));
  };

  const onSave = () => {
    onUpdate();
  };

  const updateBlockImage = (block: BlockModel) => {
    setCurrentBlockImage({
      id: block.id,
      position: block.position,
      image: block.image,
    });
  };

  return (
    <div className={`center ${styles.block}`}>
      <div className={styles.title}>
        <span>Memorial quilt square for </span>
        <span>
          {editableQuiltStore?.firstName || ''} {editableQuiltStore?.lastName || ''}
        </span>
      </div>
      <div className={styles.addTextPhoto}>
        <span className="defaultTitle">Add text and photos</span>
        <span className="defaultTex colorNavy">
          Add anything that is meaningful to you: photos, memories, stories, a eulogy, or an
          obituary. What you create is up to you.
        </span>
        <span className="defaultText colorNavy">
          Use the large box if you want to add a single large block of text, a eulogy or obituary
          for example. Only boxes containing content will display.
        </span>
      </div>
      <div className={styles.instruction}>
        <span className="defaultTitle1">Instructions:</span>
        <ul className="defaultText colorNavy">
          <li className={styles.list_item}>
            Click on an empty box to add content, choosing either text <TextFieldsIcon /> or images{' '}
            <ImageIcon />.
          </li>
          <li className={styles.list_item}>Add your text and change the font, size, or color.</li>
          <li className={styles.list_item}>
            Upload photos to your album, then select photos to add to your quilt square.
          </li>
          <li className={styles.list_item}>Drag and drop filled boxes to move them around your quilt wherever you want them.</li>
          <li className={styles.list_item}>Click PREVIEW to view your quilt square.</li>
        </ul>
      </div>
      <div className={styles.editor}>
        <RichTextEditor
          editorData={getBlockTopContent?.topContent?.top}
          setEditorData={(value) => setEditorDataById(getBlockTopContent?.id, value, null, 'top')}
          quiltId={''}
          height={400}
        />
      </div>
      {blocks && (
        <Blocks
          blocks={blocks}
          currentBlockImage={currentBlockImage}
          setImageBlock={setImageBlock}
          updateBlockImage={updateBlockImage}
          setConfirmDeleting={setConfirmDeleting}
          setEditorDataById={setEditorDataById}
          addNewBlockText={addNewBlockText}
          addNewBlockImage={addNewBlockImage}
          changePositionBlocks={changePositionBlocks}
          imageName={imageName ?? ''}
        />
      )}

      <Collapse in={currentBlockImage?.newAddedBlock} timeout={1000}>
        <CreateImagesBlock imageName={imageName ?? ''} setImageBlock={setImageBlock} />
      </Collapse>

      <div className={styles.editorBlock2}>
        <div className={styles.editorBlock2__top}>
          <ClickAddBlock
            addNewBlockText={() => addNewBlockText('half', 'left')}
            addNewBlockImage={() => addNewBlockImage('half', 'left')}
          />
          <ClickAddBlock
            addNewBlockText={() => addNewBlockText('half', 'right')}
            addNewBlockImage={() => addNewBlockImage('half', 'right')}
          />
        </div>
        <ClickAddBlock
          addNewBlockText={() => addNewBlockText()}
          addNewBlockImage={() => addNewBlockImage()}
          isFullExample
        />
      </div>

      <div className="delimitierGrey"></div>
      <div
        className={`${styles.actions} ${editableQuiltStore.isContributor ? styles.actionIsContributor : ''}`}
      >
        <div
          className={`${styles.actions__left} ${editableQuiltStore.isContributor ? styles.actionLeftIsContributor : ''}`}
        >
          {!editableQuiltStore.isContributor && (
            <CreateMemorialSteps 
              isActiveStep={isActiveStep} 
              steps={steps} 
            />
          )}
        </div>
        <div className={styles.actions__right}>
          <button className={`customButton ${styles.actions__button}`} onClick={onSave}>
            Save and Continue
          </button>
          <button className={`customButton ${styles.actions__button}`} onClick={showDialogBlocks}>
            Preview
          </button>
        </div>
      </div>

      <Loader loading={loading || loadingPublish} />

      <DefaultDialog
        open={confirmDeleting.openModal}
        setDialog={(open) => {
          setConfirmDeleting((prev) => {
            return { ...prev, openModal: !!open };
          });
        }}
        title={''}
        text={
          'Are you sure you want to delete the content box? You will lose the content along with it.'
        }
        confirmAction={() =>
          deleteBlock(confirmDeleting.block, confirmDeleting.side, confirmDeleting.typeDeleting)
        }
      />
    </div>
  );
};
