import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SearchIcon from '@mui/icons-material/Search';
import SourceIcon from '@mui/icons-material/Source';

import styles from './about.module.scss';
import { useRedirectAndScroll } from 'hooks';

const iconStyles = {
  color: '#fff',
  width: { xs: '60px', md: '90px' },
  height: { xs: '60px', md: '90px' },
  padding: '10px',
  backgroundColor: '#26a3c5',
  borderRadius: '50%',
};

const linkArray = [
  {
    title: 'Home',
    path: '/',
    icon: <HomeIcon sx={iconStyles} />,
  },
  {
    title: 'FAQ',
    path: '/faq',
    icon: <LiveHelpIcon sx={iconStyles} />,
  },
  {
    title: 'Home',
    path: '/',
    icon: <HomeIcon sx={iconStyles} />,
  },
  {
    title: 'Search',
    path: '/search',
    icon: <SearchIcon sx={iconStyles} />,
  },
  {
    title: 'FAQ',
    path: '/faq',
    icon: <LiveHelpIcon sx={iconStyles} />,
  },
  {
    title: 'Resources',
    path: '/resources',
    icon: <SourceIcon sx={iconStyles} />,
  },
  {
    title: 'Take Our Survey',
    path: '/take-our-survey',
    icon: <TravelExploreIcon sx={iconStyles} />,
  },
  {
    title: 'Profile',
    path: '/profile',
    icon: <AccountCircleIcon sx={iconStyles} />,
  },
];

const committeeArray = [
  {
    name: 'Albert McLeod',
    province: 'Manitoba',
  },
  {
    name: 'Paula Black',
    province: 'Ontario',
  },
  {
    name: 'Jill Marzetti',
    province: 'Vancouver',
  },
  {
    name: 'Cathy Cole',
    province: 'Halifax',
  },
  {
    name: 'Jane Doe',
    province: 'Quebec',
  },
  {
    name: 'Cathy Cole',
    province: 'Toronto',
  },
];

const AboutPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { onlyScroll, onlyRedirect } = useRedirectAndScroll({});

  useEffect(() => {
    const scrollTo = searchParams?.get('visible');
    if (scrollTo) {
      onlyScroll(scrollTo);
    }
  }, []);

  return (
    <section className="center">
      <h1 className="bigTitle">Title</h1>
      <p className='colorNavy'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
      <div className={styles.link__block}>
        {linkArray.map((item, index) => (
          <div className={styles.link__item} onClick={() => onlyRedirect(item.path)} key={index}>
            {item.icon}
            <p className={styles.item__text}>{item.title}</p>
          </div>
        ))}
      </div>
      <h2 className="my-30 defaultTitle">About The *Memorial Quilt*</h2>
      <p className="my-20 colorNavy">
        We are in a time of loss. With loss, we grieve. Many of us are grieving alone.
      </p>
      <p className="my-20 colorNavy">
        The *Memorial Quilt* is a sacred online healing space for people who are grieving a death to
        join in community to remember and honour their loss and their person.
      </p>
      <p className="my-20 colorNavy">
        The *Memorial Quilt* has been developed for people who have experienced loss and/or the
        death of someone they care about over the course of the global covid pandemic.
      </p>
      <p className="my-20 colorNavy">
        The *Memorial Quilt* is a work in progress; each individual quilt that is created becomes a
        patch in a wider community quilt, stitched with care. The aim is to provide you a space for
        remembrance and to bring families, friends and communities together in caring and healing as
        the effects of the pandemic continue to ripple.
      </p>
      <p className="my-20 colorNavy">
        Through the creation of the quilt, honour is also paid to Indigenous cultural practices and
        ritual – creating a space that is accessible and meaningful for all.
      </p>
      <p className="my-20 colorNavy">
        This sacred space is rooted in art, expression, spiritual healing, and storytelling, where
        biological and chose families and friends will come together the honour the life or lives
        they’ve lost through the creation of a personalized quilt square.
      </p>
      <h4 className="mt-50 defaultTitle1">The *Memorial Quilt* and Covid-19</h4>
      <p className="my-20 colorNavy">
        The impacts of the COVID-19 pandemic are like nothing Canadians have experienced in their
        lifetimes. Living with daily death tolls on the news, final goodbyes through iPads, funerals
        via zoom and postponed rituals, our end-of-life and grief rituals have been upended
        resulting in grief that can be complex.
      </p>
      <p className="my-20 colorNavy">
        The grief Canadians are experiencing, similar to populations around the world, encompasses
        non-death related grief as well. Financial hardships, relationship breakdowns, “loss of life
        as we know it” and grief experienced by healthcare providers caring on frontlines are adding
        to the distress we are experiencing as individuals, families, communities and society.
      </p>
      <p className="my-20 colorNavy">
        Social and cultural connections that are fundamental to our grieving have been delayed and
        disrupted. The *Memorial Quilt* is a space where honouring and remembrance can happen at any
        time and where these connections can be built, even as the pandemic continues to have an
        impact.
      </p>
      <h4 className="mt-50 defaultTitle1">A work in progress</h4>
      <p className="my-20 colorNavy">
        The concept of the *Memorial Quilt* was developed by Elder Albert McLeod, in partnership
        with Canadian Virtual Hospice.
      </p>
      <p className="my-20 colorNavy">
        With the partnership and support of Palliative Manitoba, The Winnipeg Foundation and
        Two-Spirited People of Manitoba Inc., the *Memorial Quilt* was brought to life, creating a
        sacred space to come together in grief as a community.
      </p>
      <p className="my-20 colorNavy">
        As the *Memorial Quilt* continues to grow, we look to community feedback about your
        experience creating your memorial and how it has been impactful for you. You can share your
        feedback, questions or suggestions by emailing{' '}
        <a href="mailto:info@virtualhospice.ca?subject=I have questions about the MemorialQuilt">
          info@virtualhospice.ca
        </a>
        .
      </p>
      <h4 className="mt-50 defaultTitle1">And its free!</h4>
      <p className="my-20 colorNavy">
        There is no charge for people in Canada to create or access the *Memorial Quilt*. There are
        no hidden costs, and no options for costly services. No one trying to sell you things. We
        NEVER share your contact information with other organizations.
      </p>
      <h3 className="mt-50 defaultTitle">*Memorial Quilt* Advisory Committee</h3>
      <div className={`mt-30 ${styles.committee__block}`}>
        {committeeArray.map((item, index) => (
          <div key={index} className='colorNavy'>
            <p className={styles.committee__name}>{item.name}</p>
            <p>{item.province}</p>
          </div>
        ))}
      </div>
      <h3 className="mt-50 defaultTitle">How to use</h3>
      <h4 className="my-30 defaultTitle1">
        Adding your personalized quilt square to the *Memorial Quilt* - create a memorial
      </h4>
      <p className="my-20 colorNavy">
        You can use a computer, laptop, tablet, or smartphone to access the *Memorial Quilt* and to
        create your own virtual quilt.
      </p>
      <p className="my-20 colorNavy">
        Creating your quilt is personal and you can take as much time as you need. You can upload
        pictures and add text to your quilt and add more content to create a memorial space around
        the quilt. The quilt can be private, or it can be shared as a patch of the wider community
        quilt.
      </p>
      <p className="my-20 colorNavy">Follow the easy steps on the website to create your *Memorial Quilt*.</p>
      <h4 className="my-30 defaultTitle1">Invite others to contribute</h4>
      <p className="my-20 colorNavy">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
      <h4 className="my-30 defaultTitle1">It's about community/share your memorial</h4>
      <p className="my-20 colorNavy">
        You can share your *Memorial Quilt* and your memorial page with family, friends or others.
        What you share is up to you. You can change what you share at any time.
      </p>
      <h4 className="my-30 defaultTitle1">Search quilts</h4>
      <p className="my-20 colorNavy">
        You can search for quilts by name, location or other criteria. You can search for quilts
        that have been shared with you, or for quilts that are public.
      </p>
      <h4 className="my-30 defaultTitle1">Add a tribute/condolence</h4>
      <p className="my-20 colorNavy">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
      <h4 className="my-30 defaultTitle1">Send a whisper message</h4>
      <p className="my-20 colorNavy">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
      <h3 className="mt-50 mb-30 defaultTitle">User support</h3>
      <p className="my-20 colorNavy">
        While we have made every effort to ensure you have a positive experience, technical
        difficulties can occur. If you have trouble using the *Memorial Quilt*, please refer to our
        Help Section. If you require further support or have general questions about the *Memorial
        Quilt*, contact:{' '}
        <a href="mailto:info@virtualhospice.ca?subject=I have questions about the MemorialQuilt">
          info@virtualhospice.ca
        </a>
        . There is no charge for technical support.
      </p>
      <h3 className="mt-50 defaultTitle">Frequently asked questions</h3>
      <h4 className="defaultTitle1">
        I didn’t get a confirmation email when I signed up. What should I do?
      </h4>
      <p className="my-20 colorNavy">
        [SITE NAME] automatically sends a confirmation email to the email address you used to make
        your account. Check your “Junk” or “Spam” folder for the confirmation email to verify your
        account. If you still need help, email us at{' '}
        <a href="mailto:info@virtualhospice.ca?subject=I have questions about the MemorialQuilt">
          info@virtualhospice.ca
        </a>
        .
      </p>
      <h4 className="defaultTitle1">Will [SITE NAME] cost me any money?</h4>
      <p className="my-20 colorNavy">
        No. [SITE NAME] is completely free for Canadians. There are no hidden fees, no options for
        costly services. No one at [SITE NAME] will ever try to sell you anything.
      </p>
      <h4 className="defaultTitle1">Can I use a smartphone or tablet?</h4>
      <p className="my-20 colorNavy">
        Yes. [SITE NAME] is compatible with all platforms using any browser (e.g., Chrome, Safari).
        You use it on smartphones, tablets, or personal computers.
      </p>
      <h4 className="defaultTitle1">What if I still have questions?</h4>
      <p className="my-20 colorNavy">
        If you have questions, email us at{' '}
        <a href="mailto:info@virtualhospice.ca?subject=I have questions about the MemorialQuilt">
          info@virtualhospice.ca
        </a>
        .
      </p>
      <h4 className="defaultTitle1">What if I have technical problems?</h4>
      <p className="my-20 colorNavy">
        If you have trouble accessing [SITE NAME], logging in, or with any of the features, please
        email us at{' '}
        <a href="mailto:info@virtualhospice.ca?subject=I have questions about the MemorialQuilt">
          info@virtualhospice.ca
        </a>
        .
      </p>
      <h4 className="defaultTitle1">How can I help support this project?</h4>
      <p className="my-20 colorNavy">
        We welcome new partnerships and opportunities to expand the use of the CareHub across
        Canada. To learn more, organize an awareness or training session for your organization, or
        get involved, email{' '}
        <a href="mailto:info@virtualhospice.ca?subject=I have questions about the MemorialQuilt">
          info@virtualhospice.ca
        </a>
        .
      </p>
      <h3 id="notice" className="mt-50 mb-30 defaultTitle">
        Canadian Healing Quilt Privacy and Use Notice
      </h3>
      <p className="my-20 colorNavy">Last Updated: September 2024</p>
      <p className="my-20 colorNavy">
        The Canadian Healing Quilt is operated by Canadian Virtual Hospice, a registered charity.
      </p>
      <p className="my-20 colorNavy">
        By using the Canadian Healing Quilt you are agreeing to the{' '}
        <a
          href="https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Right+Footer+Navigation/Legal+Notices.aspx"
          target="_blank"
          rel="noreferrer"
        >
          Legal Notices / Privacy Policy
        </a>
        .
      </p>
      <p className="my-20 colorNavy">By using the Canadian Healing Quilt, you also confirm:</p>
      <ul className='colorNavy'>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>if you create a Healing Quilt, that you are or
          have the permission of the administrator or representative of the person you are
          commemorating;
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>if you post to a Healing Quilt, that you have the
          right to post the photos and contents you are posting to a Healing Quilt; and
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>your use of the Canadian Healing Quilt will not
          be exploitive, obscene, harmful, threatening, abusive, harassing, hateful, defamatory,
          sexually explicit or pornographic, violent, inflammatory, or discriminatory based on race,
          sex, religion, nationality, disability, sexual orientation, or age or other such legally
          prohibited ground or be otherwise objectionable, such determination to be made in Canadian
          Virtual Hospice’s sole discretion.
        </li>
      </ul>
      <p className="my-20 colorNavy">
        For more information, please see the{' '}
        <a
          href="https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Right+Footer+Navigation/Legal+Notices.aspx"
          target="_blank"
          rel="noreferrer"
        >
          Legal Notices / Privacy Policy
        </a>
        .
      </p>
      <p className="mt-20 mb-50 colorNavy">
        If you have any questions about the Canadian Healing Quilt or the privacy practices of
        Canadian Virtual Hospice, please contact{' '}
        <a href="mailto:info@virtualhospice.ca?subject=I have questions about the Canadian Healing Quilt">
          info@virtualhospice.ca
        </a>
        .
      </p>
    </section>
  );
};

export default AboutPage;
