export enum OptionsWrapEnum {
  l = '1820px',
  m = '1512px',
  s = '1177px',
  default = '-webkit-fill-available',
}

export enum OptionsRowType {
  Stub = 'stub',
  Type = 'type',
  Type1 = 'type1',
}
