import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { hideDialog } from 'store/reducers/dialogsSlice';
import { useQuilt, useRedirectAndScroll } from 'hooks';
import Header from '../../Header';
import { HeaderInfo } from './HeaderInfo';
import LineBlock from '../../LineBlock';
import { styleImageByType } from '../CreateMemorialQuiltStepThree/const';
import TributesAndCondolences from '../../TributesAndCondolences';
import { getArtificialBlockSetting } from './utils';
import { TControllerReadLessValue, DialogBlocksProps } from './types';
import { heightRead, initialValueControllerReadLessBody } from './const';
import { AccessPermissionsTextEnum } from '../CreateMemorialQuiltStepTwo/const';
import stylesThree from '../CreateMemorialQuiltStepThree/styles.module.scss';
import styles from './styles.module.scss';

export const DialogBlocks: FC<DialogBlocksProps> = ({
  blocksProps,
  isViewPage,
  isTributes,
  editButtonStatus,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { redirectAndScroll } = useRedirectAndScroll({});
  const { id } = useParams();
  const { blocks: blocksUseQuilt } = useQuilt({ id: id || '' });
  const blocks = blocksProps ? blocksProps : blocksUseQuilt.getBlocksEditableQuilt();
  const blocksDialog = useAppSelector((state) => state.dialogs.dialogsState.blocks);

  const [controllerReadLess, setControllerReadLess] = useState<
    Record<string, TControllerReadLessValue>
  >(() => {
    // init id for this
    const controllerReadLessData: Record<string, TControllerReadLessValue> = {
      top: initialValueControllerReadLessBody,
    };

    for (const block of blocks) {
      if (typeof block.editorData === 'object' && block.editorData.left) {
        controllerReadLessData[`${block.id}-left`] = initialValueControllerReadLessBody;
      }

      if (typeof block.editorData === 'object' && block.editorData.right) {
        controllerReadLessData[`${block.id}-right`] = initialValueControllerReadLessBody;
      }

      if (typeof block.editorData === 'string') {
        controllerReadLessData[`${block.id}`] = initialValueControllerReadLessBody;
      }
    }

    return controllerReadLessData;
  });

  const controllerReadLessHeight = useRef<Record<string, number>>({
    ...Object.keys(controllerReadLess).reduce(
      (total, item) => {
        total[item] = heightRead;

        return total;
      },
      {} as Record<string, number>
    ),
  });

  const blockTopContent = blocks.find((block) => block.topContent);

  // handler for browser arrows
  useEffect(() => {
    const handlePopState = (event: any) => {
      if (blocksDialog) {
        dispatch(hideDialog('blocks'));
        if (window.history.length > 1) {
          navigate(1);
        } else {
          navigate('/');
        }
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const actionControllerReadLess = (id: string, html: string) => {
    if (controllerReadLess?.[id]?.isShowReadLess) return;

    const isFullText = !id.includes('-left') && !id.includes('-right');

    if (getArtificialBlockSetting(html, isFullText).height >= heightRead) {
      // @ts-ignore
      setControllerReadLess((state) => {
        return {
          ...state,
          [id]: {
            isShowReadLess: true,
            isShow: false,
          },
        };
      });
    }
  };

  const actionReadLessIsShow = (id: string, value: boolean) => {
    setControllerReadLess((state) => {
      if (!state) return state;

      return {
        ...state,
        [id]: {
          ...state[id],
          isShow: value,
        },
      };
    });
  };

  const getReadLessStyleByState = (id: string, html?: string) => {
    if (html) {
      return controllerReadLess?.[id]?.isShow ? 'auto' : controllerReadLessHeight.current[id];
    }

    return controllerReadLess?.[id]?.isShow ? 'auto' : heightRead;
  };

  const onBack = () => {
    dispatch(hideDialog('blocks'));
  };

  const getHTMLAfterCheckHeight = (html: string, id: string) => {
    const isFullText = !id.includes('-left') && !id.includes('-right');
    const { element, height } = getArtificialBlockSetting(html, isFullText);
    let newHtml = '';

    if (height < heightRead) {
      return html;
    }

    if ((getReadLessStyleByState(id) as number) >= heightRead) {
      let index = 0;
 
      for (const child of Array.from(element.children)) {
        let htmlValidate = newHtml + child.outerHTML;

        const { height } = getArtificialBlockSetting(htmlValidate, isFullText);

        if (height >= heightRead) {
          controllerReadLessHeight.current = {
            ...controllerReadLessHeight.current,
            [id]: heightRead,
          };
          return index === 0 ? child.outerHTML : htmlValidate;
        } else {
          newHtml += child.outerHTML;
        }

        ++index;
      }
    } else {
      return html;
    }

    return '';
  };

  return (
    <>
      {!isViewPage && <Header />}

      <div
        className={isViewPage ? '' : styles.wrap}
        style={{
          background: 'center / cover no-repeat url("/img/general/background-preview.png")',
        }}
      >
        <div className={styles.headerImg}>
          {!isViewPage && <LineBlock />}
          <img
            className={styles.imgLine}
            src="/img/general/top-line-header-preview.png"
            alt="line"
          />
        </div>

        <div
          className="center"
          style={{
            width: '100%',
            overflowWrap: 'break-word',
          }}
        >
          <HeaderInfo />

          {blockTopContent && (
            <div className={`${stylesThree.blocks} ${styles.blocks_mt}`}>
              {blockTopContent?.topContent?.top && (
                /* @ts-ignore */
                <div className={styles.textBlock} onClick={actionControllerReadLess('top', blockTopContent.topContent?.top || '')}>
                  <div
                    className={styles.textForPrint}
                    style={{
                      height: getReadLessStyleByState('top', blockTopContent.topContent?.top),
                      transition: '500ms height ease',
                      overflow: 'hidden',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: getHTMLAfterCheckHeight(blockTopContent.topContent?.top || '', 'top'),
                    }}
                  ></div>

                  {controllerReadLess?.['top']?.isShowReadLess && (
                    <div
                      className={styles.read}
                      onClick={() =>
                        actionReadLessIsShow('top', !controllerReadLess?.['top'].isShow)
                      }
                    >
                      {controllerReadLess?.['top'].isShow ? 'Show less' : 'Read more'}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {blocks && blocks?.length > 0 ? (
            <div className={stylesThree.blocks}>
              {blocks?.map((block, index) => {
                return (
                  <div key={index}>
                    {block.position === 'full' && block.image && 'file' in block.image ? (
                      <div className={stylesThree.editorBlock__imageFullNoCursor}>
                        <img
                          src={block.image.file}
                          alt="builderPicture"
                          className={`${styleImageByType[block.image.type]} ${styles.imageBorder}`}
                        />
                      </div>
                    ) : block.position === 'full' && typeof block.editorData === 'string' ? (
                      // @ts-ignore
                      <div className={styles.textBlock} onClick={actionControllerReadLess(block.id, block.editorData || '')}>
                        <div
                          style={{
                            height: getReadLessStyleByState(block.id, block.editorData),
                            transition: '500ms height ease',
                            overflow: 'hidden',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: getHTMLAfterCheckHeight(block.editorData || '', `${block.id}`),
                          }}
                          className={`${styles.textBlock__content} textForPrint`}
                        ></div>

                        {controllerReadLess?.[block.id]?.isShowReadLess && (
                          <div
                            className={styles.read}
                            onClick={() =>
                              actionReadLessIsShow(block.id, !controllerReadLess?.[block.id].isShow)
                            }
                          >
                            {controllerReadLess?.[block.id].isShow ? 'Show less' : 'Read more'}
                          </div>
                        )}
                      </div>
                    ) : null}

                    <div className={stylesThree.editorBlock2__top} key={block.id}>
                      <div data-side="left" className={styles.containerPart}>
                        {typeof block.editorData === 'object' && block.editorData.left?.value ? (
                          // @ts-ignore
                          <div className={styles.textBlock} onClick={actionControllerReadLess(`${block.id}-left`, block.editorData.left?.value || '')}>
                            <div
                              style={{
                                height: getReadLessStyleByState(
                                  `${block.id}-left`,
                                  block.editorData.left?.value
                                ),
                                transition: '500ms height ease',
                                overflow: 'hidden',
                              }}
                              dangerouslySetInnerHTML={{
                                __html: getHTMLAfterCheckHeight(
                                  block.editorData.left?.value || '',
                                  `${block.id}-left`
                                ),
                              }}
                              className={`${styles.textBlock__content} textForPrint`}
                            ></div>

                            {controllerReadLess?.[`${block.id}-left`]?.isShowReadLess && (
                              <div
                                className={styles.read}
                                onClick={() =>
                                  actionReadLessIsShow(
                                    `${block.id}-left`,
                                    !controllerReadLess?.[`${block.id}-left`].isShow
                                  )
                                }
                              >
                                {controllerReadLess?.[`${block.id}-left`].isShow
                                  ? 'Show less'
                                  : 'Read more'}
                              </div>
                            )}
                          </div>
                        ) : block.image &&
                          'left' in block.image &&
                          block.image.left?.image?.file &&
                          block.image.left?.isRender ? (
                          <div className={stylesThree.editorBlock__imageNoCursor}>
                            <img
                              src={block.image.left.image.file}
                              alt="builderPicture"
                              className={`${styleImageByType[block.image.left.image.type]} ${styles.imageBorder} imageForPrint`}
                            />
                          </div>
                        ) : null}
                      </div>

                      <div data-side="right" className={styles.containerPart}>
                        {typeof block.editorData === 'object' && block.editorData.right?.value ? (
                          // @ts-ignore
                          <div className={styles.textBlock} onClick={actionControllerReadLess(`${block.id}-right`, block.editorData.right?.value || '')}>
                            <div
                              style={{
                                height: getReadLessStyleByState(
                                  `${block.id}-right`,
                                  block.editorData.right?.value
                                ),
                                transition: '500ms height ease',
                                overflow: 'hidden',
                              }}
                              dangerouslySetInnerHTML={{
                                __html: getHTMLAfterCheckHeight(
                                  block.editorData.right?.value || '',
                                  `${block.id}-right`
                                ),
                              }}
                              className={`${styles.textBlock__content} textForPrint`}
                            ></div>

                            {controllerReadLess?.[`${block.id}-right`]?.isShowReadLess && (
                              <div
                                className={styles.read}
                                onClick={() =>
                                  actionReadLessIsShow(
                                    `${block.id}-right`,
                                    !controllerReadLess?.[`${block.id}-right`].isShow
                                  )
                                }
                              >
                                {controllerReadLess?.[`${block.id}-right`].isShow
                                  ? 'Show less'
                                  : 'Read more'}
                              </div>
                            )}
                          </div>
                        ) : block.image &&
                          'right' in block.image &&
                          block.image.right?.image?.file &&
                          block.image.right?.isRender ? (
                          <div className={stylesThree.editorBlock__imageRightNoCursor}>
                            <img
                              src={block.image.right.image.file || ''}
                              alt={'builderPicture'}
                              className={`${styleImageByType[block.image.right.image.type]} ${styles.imageBorder} imageForPrint`}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={styles.emptyMessage}></div>
          )}
          
          {isTributes && (
            <Box mt={4} className="hiddenInPrint">
              <TributesAndCondolences />
            </Box>
          )}

          <div className={stylesThree.blocks}>
            <div className={`delimitierGrey ${styles.example__line}`}></div>
            {isViewPage ? (
              <div className={styles.button__block}>
                <button className="customButton" onClick={() => navigate(-1)}>
                  Back
                </button>
                {editButtonStatus === AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL ? (
                  <button
                    className="customButton"
                    onClick={() => redirectAndScroll('/update/' + id + '?step=3')}
                  >
                    Edit
                  </button>
                ) : editButtonStatus === AccessPermissionsTextEnum.EDIT_IMAGES ? (
                  <button
                    className="customButton"
                    onClick={() => redirectAndScroll('/add-images/' + id)}
                  >
                    Edit Images
                  </button>
                ) : null}
              </div>
            ) : (
              <div className={styles.button__block}>
                <button className="customButton" onClick={onBack}>
                  EDIT MEMORIAL
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
